import {initializeApp} from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  setPersistence,
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  writeBatch,
  limit,
} from 'firebase/firestore';
import Swal from 'sweetalert2';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

let firebaseInitialized = false;

const initializeFirebaseApp = () => {
  return new Promise((resolve, reject) => {
    if (firebaseInitialized) {
      resolve(true);
    } else {
      onAuthStateChanged(
          auth,
          (user) => {
            if (user) {
            // console.log("Firebase Initialized with user:", user.email);
              firebaseInitialized = true;
              resolve(true);
            } else {
            // console.log("Firebase Initialized without user.");
              firebaseInitialized = true;
              resolve(false);
            }
          },
          reject,
      );
    }
  });
};

const getAppleProvider = () => {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  return provider;
};

const getFirebaseApp = () => app;

const getFirebaseAuth = () => auth;

const getFirebaseDb = () => db;

export function ensureAuthIsReady() {
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(
        (user) => {
          unsubscribe(); // Important to unsubscribe after confirmation
          resolve(user); // Resolve the promise with the user object
        },
        (error) => {
        // console.error("Authentication state failed to initialize:", error);
          resolve(null); // Resolve with null on error
        },
    );
  });
}

// Check the authentication status
onAuthStateChanged(auth, async (user) => {
  if (user) {
    // console.log("user is authenticated");
  } else {
    // console.log("No authenticated user");
  }
});

async function updateCardDetailsAdded(cardAdded) {
  // console.log("Starting updateCardDetailsAdded function...");

  // console.log("Ensuring authentication is ready...");
  const user = await ensureAuthIsReady(); // Directly use the returned user

  if (!user) {
    // console.error("No user logged in");
    Swal.fire({
      icon: 'error',
      title: 'Login Required',
      text: 'Please login to update your card details.',
    });

    return;
  }

  const userEmail = user.email;
  const docPath = `users/${userEmail}/Settings/info`;
  const docRef = db.doc(docPath);

  try {
    await docRef.set(
        {
          cardDetailsAdded: cardAdded,
        },
        {merge: true},
    );
  } catch (error) {
    throw new Error(
        `Failed to update document at ${docPath}: ${error.message}`,
    );
  }
}

const deleteAccountAction = async () => {
  const auth = getFirebaseAuth();
  const user = auth.currentUser;

  if (!user) {
    await Swal.fire({
      icon: 'error',
      title: 'Login Required',
      text: 'Please login to delete your account.',
    });
    return false; // Indicate account was not deleted
  }

  try {
    const userEmail = user.email;
    const lockPath = `users/${userEmail}/Settings/info`;
    const lockDocRef = doc(db, lockPath);
    const lockDoc = await getDoc(lockDocRef);
    if (lockDoc.exists() && lockDoc.data().deleteAccountLockUntil) {
      const lockUntilTimestamp = lockDoc.data().deleteAccountLockUntil;
      if (lockUntilTimestamp.toMillis() > Date.now()) {
        const lockUntilDate = lockUntilTimestamp.toDate();
        const now = new Date();
        const timeDiff = lockUntilDate - now;
        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        await Swal.fire({
          icon: 'info',
          title: 'Account Deletion Locked',
          text: `You cannot delete your account right now as you have locked it for ${days} day(s), ${hours} hour(s), and ${minutes} minute(s).`,
        });
        return false; // Indicate account was not deleted
      }
    }

    // Proceed to delete user data and account
    await deleteUserRelatedData(userEmail);

    const token = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/, '$1');

    const deletePaymentsResponse = await fetch('/delete-all-payment-methods', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      body: JSON.stringify({}),
    });

    const response = await fetch('/logout-or-delete', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      body: JSON.stringify({action: 'delete-account'}),
    });

    await user.delete();

    if (response.ok && deletePaymentsResponse.ok) {
      // Handle successful account deletion
      console.log('Account deleted successfully');
      return true; // Indicate account was deleted
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Account Deletion Failed',
        text: 'Failed to delete account. Please try again later.',
      });
      return false; // Indicate account was not deleted
    }
  } catch (error) {
    await Swal.fire({
      icon: 'error',
      title: 'Account Deletion Failed',
      text: 'Failed to delete account. Please try again later.',
    });
    if (error.code === 'auth/requires-recent-login') {
      await Swal.fire({
        icon: 'info',
        title: 'Re-login Required',
        text: 'Please re-login to delete your account.',
      });
    }
    return false; // Indicate account was not deleted
  }
};

const getUserAccountBalance = async () => {
  const user = auth.currentUser;
  if (!user) {
    return 0; // Return 0 if no user is logged in
  }
  const userEmail = user.email;
  const lockPath = `users/${userEmail}/Settings/info`;
  const lockDocRef = doc(db, lockPath);

  try {
    const lockDoc = await getDoc(lockDocRef);
    // Check if the document exists and has the accountBalance property
    if (lockDoc.exists() && lockDoc.data().hasOwnProperty('accountBalance')) {
      const accountBalance = lockDoc.data().accountBalance;
      return accountBalance;
    } else {
      return 0; // Return 0 if the accountBalance field is undefined
    }
  } catch (error) {
    return 0; // Return 0 on any error
  }
};

const canDeletePaymentMethod = async () => {
  const user = auth.currentUser;

  if (!user) {
    return;
  }

  const userEmail = user.email;
  const lockPath = `users/${userEmail}/Settings/info`;
  const lockDocRef = doc(db, lockPath);

  try {
    const lockDoc = await getDoc(lockDocRef);
    if (lockDoc.exists()) {
      const lockData = lockDoc.data();
      const lockUntilTimestamp = lockData.deletePaymentMethodLockUntil;
      if (lockUntilTimestamp && lockUntilTimestamp.toMillis() > Date.now()) {
        const lockUntilDate = lockUntilTimestamp.toDate();
        const now = new Date();
        const timeDiff = lockUntilDate - now;

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

        const message =
          'You cannot delete your payment method right now as you have locked it for ';
        const parts = [];
        if (days > 0) parts.push(days + ' day(s)');
        if (hours > 0) parts.push(hours + ' hour(s)');
        if (minutes > 0) parts.push(minutes + ' minute(s)');

        Swal.fire({
          icon: 'info',
          title: 'Payment Method Deletion Locked',
          text: message + parts.join(', ') + '.',
        });
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'An error occurred while processing your request. Please try again later.',
    });
  }
};

async function deleteUserRelatedData(userEmail) {
  const userRef = doc(db, 'users', userEmail);
  const subcollections = [
    'Forfeits',
    'Settings',
    'DailyChallenges',
    'EditAppealDailyChallenges',
    'EditAppealForfeits',
    'Holidays',
    'RoutineForfeits',
    'Appeals',
    'Punishments',
  ];

  // Iterate over each subcollection and delete its documents
  for (const subcollection of subcollections) {
    await deleteCollectionDocuments(userRef, subcollection);
  }

  // Deleting user document itself
  await deleteDoc(userRef);

  // Example: Deleting user chats
  await deleteCollectionDocuments(doc(db, 'chats', userEmail), 'messages');
}

async function deleteCollectionDocuments(parentRef, collectionName) {
  const collectionRef = collection(parentRef, collectionName);
  const batchSize = 500;
  let querySnapshot;

  do {
    const q = query(collectionRef, limit(batchSize));
    querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((docToDelete) => {
      batch.delete(docToDelete.ref);
    });

    await batch.commit();
  } while (querySnapshot.size >= batchSize);
}

export {
  getAuth,
  getFirebaseApp,
  getFirebaseAuth,
  getFirebaseDb,
  initializeFirebaseApp,
  updateCardDetailsAdded,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  getUserAccountBalance,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  deleteAccountAction,
  canDeletePaymentMethod,
  getAppleProvider,
};
