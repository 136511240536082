// Import Firebase app and necessary functions

import {
  getFirebaseApp,
  getFirebaseAuth,
  getFirebaseDb,
  initializeFirebaseApp,
  updateCardDetailsAdded,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  getUserAccountBalance,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  deleteAccountAction,
  canDeletePaymentMethod,
  ensureAuthIsReady,
  getAppleProvider,
} from './firebase.js';
import Swal from 'sweetalert2';

initializeFirebaseApp()
    .then((isLoggedIn) => {
    // console.log("Firebase is initialized, User logged in:", isLoggedIn);
    // Now you can safely use Firebase Auth, Firestore, etc.
    })
    .catch((error) => {
    // console.error("Error initializing Firebase:", error);
    });

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (const registration of registrations) {
      registration.unregister().then(() => {
        console.log('Service Worker unregistered successfully');
      }).catch((err) => {
        console.log('Service Worker unregistration failed: ', err);
      });
    }
  }).catch(function(err) {
    console.log('Service Worker registration check failed: ', err);
  });
}


// Handle email/password login
const loginButton = document.getElementById('login');
if (loginButton) {
  loginButton.addEventListener('click', async () => {
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const auth = getFirebaseAuth();
    try {
      const result = await signInWithEmailAndPassword(auth, email, password).catch((error) => {
        console.log('error: ', error);
      });
      const userCredential = result;

      // Post the idToken to your server to apply the cookie
      fetch('/apply-cookie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userCredential}),
      })
          .then((response) => response.json())
          .then((data) => {
          // Optionally store user details or redirect user
            window.location.replace('/account'); // Redirect to account page or other internal page
          })
          .catch((error) => {
            console.log('error: ', error);
          });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Please check your credentials and try again.',
      });
    }
  });
}


const googleLoginButton = document.getElementById('google-btn');
if (googleLoginButton) {
  googleLoginButton.addEventListener('click', async () => {
    // Handle Google Sign-In
    const provider = new GoogleAuthProvider();
    const auth = getFirebaseAuth();
    try {
      // Trigger the Google Sign-In popup
      const result = await signInWithPopup(auth, provider);
      const userCredential = result;
      console.log('userCredential: ', userCredential);
      // Post the idToken to your server to apply the cookie
      fetch('/apply-cookie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userCredential}),
      })
          .then((response) => response.json())
          .then((data) => {
          // Optionally store user details or redirect user
            window.location.replace('/account'); // Redirect to account page or other internal page
          })
          .catch((error) => {});
    } catch (error) {
      if (error.code === 'auth/cancelled-popup-request') {
        // Silently handle the specific error
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: 'Please check your credentials and try again.',
        });
      }
    }
  });
}

document.addEventListener('DOMContentLoaded', (event) => {
  const appleButton = document.getElementById('apple-btn');
  if (appleButton) {
    appleButton.addEventListener('click', async () => {
      const provider = getAppleProvider();
      const auth = getFirebaseAuth();

      try {
        const result = await signInWithPopup(auth, provider).catch((error) => {
          console.log('error: ', error);
        });
        const userCredential = result;

        // Post the idToken to your server to apply the cookie
        fetch('/apply-cookie', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userCredential}),
        })
            .then((response) => response.json())
            .then((data) => {
            // Optionally store user details or redirect user
              window.location.replace('/account'); // Redirect to account page or other internal page
            })
            .catch((error) => {});
      } catch (error) {
        if (error.code === 'auth/cancelled-popup-request') {
          // Silently handle the specific error
        } else {
          console.log('error: ', error),
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: 'Please check your credentials and try again.',
          });
        }
      }
    });
  }
});

const registerButton = document.getElementById('register');
if (registerButton) {
  registerButton.addEventListener('click', async () => {
    // Perform the registration
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const auth = getFirebaseAuth();
    try {
      const result = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
      );
      const userCredential = result;
      // Post the idToken to your server to apply the cookie
      fetch('/apply-cookie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({userCredential}),
      })
          .then((response) => response.json())
          .then((data) => {
          // Optionally store user details or redirect user
            window.location.replace('/account'); // Redirect to account page or other internal page
          })
          .catch((error) => {});
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: 'Please check your credentials and try again.',
      });
    }
  });
}

document.addEventListener('DOMContentLoaded', function() {
  const logoutButton = document.getElementById('log-out');
  if (logoutButton) {
    logoutButton.addEventListener('click', async () => {
      const auth = getFirebaseAuth();
      try {
        await signOut(auth); // Sign out using Firebase auth

        // Send a request to the server to clear the cookie
        const response = await fetch('/clear-cookie', {
          method: 'POST',
        });

        if (response.ok) {
          window.location.replace('/login'); // Redirect user after successful logout
        } else {
          throw new Error('Failed to clear user session.');
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Logout Failed',
          text: 'Please try again later.',
        });
      }
    });
  }
});

document.addEventListener('DOMContentLoaded', async function() {
  const deleteAccountButton = document.getElementById('delete-account');

  if (deleteAccountButton) {
    deleteAccountButton.addEventListener('click', async () => {
      const auth = getFirebaseAuth();

      const user = auth.currentUser;

      if (!user) {
        return;
      }

      await Swal.fire({
        title: 'Delete Account?',
        text: 'Are you sure you want to delete your account? All your data will be lost.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Delete Account',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const didDelete = await deleteAccountAction();
          // After deleting the account, clear the cookie
          if (didDelete) {
            const clearResponse = await fetch('/clear-cookie', {
              method: 'POST',
            });

            if (clearResponse.ok) {
              await Swal.fire({
                icon: 'success',
                title: 'Account Deleted',
                text: 'Your account has been successfully deleted, along with all of your data. We hope to see you again soon!',
              });
              window.location.replace('/login'); // Redirect user after account deletion
            } else {
              throw new Error('Failed to clear user session.');
            }
          }
        }
      });
    });
  }
});

document.addEventListener('DOMContentLoaded', async function() {
  const forfeitPointsContainer = document.getElementById('forfeit-points');
  if (forfeitPointsContainer) {
    forfeitPointsContainer.classList.add('skeleton');

    await ensureAuthIsReady(); // Wait for auth to be ready
    const accountBalance = await getUserAccountBalance();

    if (accountBalance !== null) {
      forfeitPointsContainer.innerHTML = ` 
              <div class="card points-card">
                  <span class="label">Forfeit Points:</span>
                  <span class="value">${accountBalance}</span>
              </div>
          `;
    } else {
      forfeitPointsContainer.innerHTML = `
              <div class="card points-card">
                  <span class="label">Forfeit Points:</span>
                  <span class="value">0</span>
              </div>
          `;
    }
    forfeitPointsContainer.classList.remove('skeleton');
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const burgerMenu = document.getElementById('burger-menu');
  const navMenu = document.getElementById('nav-menu');

  if (burgerMenu && navMenu) {
    burgerMenu.addEventListener('click', function() {
      navMenu.classList.toggle('hidden');
    });
  }
});

document.addEventListener('DOMContentLoaded', async function() {
  const paymentMethodContainer = document.getElementById(
      'default-payment-method',
  );

  if (paymentMethodContainer) {
    // Apply the skeleton class to start the shimmer effect
    paymentMethodContainer.classList.add('skeleton');

    // Mapping of card brands to their respective SVG filenames
    const cardBrandImages = {
      alipay: 'alipay.svg',
      amex: 'amex.svg',
      diners: 'diners.svg',
      discover: 'discover.svg',
      elo: 'elo.svg',
      generic: 'generic.svg', // Use this as a fallback option
      hiper: 'hiper.svg',
      hipercard: 'hipercard.svg',
      jcb: 'jcb.svg',
      maestro: 'maestro.svg',
      mastercard: 'mastercard.svg',
      mir: 'mir.svg',
      paypal: 'paypal.svg',
      unionpay: 'unionpay.svg',
      visa: 'visa.svg',
      // Add additional brands as needed
    };

    try {
      // Fetch the payment method information from the backend API
      const token = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/, '$1');
      // Fetch the setup intent's client secret and customer ID from the server
      const response = await fetch('/default-payment-method', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const paymentMethod = await response.json();

      // Remove the shimmer effect
      paymentMethodContainer.classList.remove('skeleton');

      if (response.ok && paymentMethod) {
        const cardImageFile =
          cardBrandImages[paymentMethod.brand] || 'generic.svg';
        const cardImageUrl = `/payment-method-svg/${cardImageFile}`;

        paymentMethodContainer.innerHTML = `
                  <div class="card dynamic-card">
                      <img src="${cardImageUrl}" alt="${
  paymentMethod.brand
}" style="height: 40px; margin-right: 20px;">
                      <span class="label">${capitalizeFirstLetter(
      paymentMethod.brand,
  )}</span>
                      <span class="label" style="margin-left: auto;">(${
  paymentMethod.last4
})</span>
                      <img src="./images/bin.svg" alt="Delete Payment Method" class="bin-icon" data-payment-method-id="${
  paymentMethod.id
}">
                  </div>
              `;
      } else {
        // If no payment method is found, show the "Add Card" button directly
        paymentMethodContainer.innerHTML = `
                  <a href="/add-card" class="card dynamic-card" style="text-decoration: none; color: inherit;">
                      <span class="label">Add payment method</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px">
                          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"/>
                      </svg>
                  </a>
              `;
      }

      // Add event listener to the "Add Card" button if it exists
      const addCardButton = paymentMethodContainer.querySelector('#add-card');
      if (addCardButton) {
        addCardButton.addEventListener('click', function() {
          window.location.href = '/add-card';
        });
      }

      // Add event listener to the bin icon after it's inserted into the DOM
      const binIcon = paymentMethodContainer.querySelector('.bin-icon');
      if (binIcon) {
        binIcon.addEventListener('click', deleteAllPaymentMethods);
      }
    } catch (error) {
      // Remove the shimmer effect and show the error message
      paymentMethodContainer.classList.remove('skeleton');
      paymentMethodContainer.innerHTML = `
              <div class="card dynamic-card">
                  <span class="label">Error retrieving payment method. Please try again later.</span>
              </div>
          `;

      // Add event listener to the "Add Card" button
      const addCardButton = paymentMethodContainer.querySelector('#add-card');
      if (addCardButton) {
        addCardButton.addEventListener('click', function() {
          window.location.href = '/add-card';
        });
      }
    }

    // Helper function to capitalize the first letter of the card brand name
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
});

// Function to delete all payment methods
async function deleteAllPaymentMethods(event) {
  const paymentMethodId = event.target.getAttribute('data-payment-method-id');
  if (paymentMethodId) {
    await Swal.fire({
      title: 'Delete Payment Method?',
      text: 'Are you sure you want to delete this payment method?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const canDelete = await canDeletePaymentMethod();
        if (canDelete) {
          // If no lock is active, proceed to delete the payment methods
          try {
            const deleteAllPaymentMethodResponse = await fetch(
                '/delete-all-payment-methods',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${document.cookie.replace(
                        /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
                        '$1',
                    )}`,
                  },
                  body: JSON.stringify({paymentMethodId}),
                },
            );

            if (deleteAllPaymentMethodResponse.ok) {
              try {
                const cardAdded = false;
                const cardUpdateResponse = await fetch(
                    '/update-card-details-added',
                    {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${document.cookie.replace(
                            /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
                            '$1',
                        )}`, // Assuming the token is stored in a cookie named 'access_token'
                      },
                      body: JSON.stringify({cardAdded}),
                    },
                );

                if (cardUpdateResponse.ok) {
                  const message = await cardUpdateResponse.json();
                  // Handle successful response here, maybe update UI or notify user
                } else {
                  throw new Error(
                      'Failed to update card details: ' +
                      cardUpdateResponse.statusText,
                  );
                }
              } catch (error) {
                Swal.fire({
                  title: 'Error updating card details',
                  text: 'An error occurred while updating your card details. Please try again later.',
                });
              }
            } else {
              throw new Error(
                  'Failed to delete payment methods: ' +
                  deleteAllPaymentMethodResponse.statusText,
              );
            }
          } catch (error) {
            Swal.fire({
              title: 'Error deleting payment method',
              text: 'An error occurred while deleting your payment method. Please try again later.',
            });
          }

          window.location.reload();
        }
      }
    });
  }
}
